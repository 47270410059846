import React from "react";
import Header from "../components/header/header";
import CountdownTimer from "../components/extras/countdown";
import HomeImage from '../images/headers/ouhome.png';    /* rename image when changed : see tip ## */
import Logo from '../images/mdm2025.png';
import '../App.css';
import '../style/home.css';
import CustomLink from "../components/navigation/customlink";
import {Link} from "react-router-dom";
import Map from "../components/extras/Map";
// import Logo25 from "../images/MDM-Logo.png";

const Home = () => {
    return (
        <>
            <header>
                <span className="welcome">Join us February 28 - March 2, 2025 for the 7th annual</span>
                <Header text=" " text2=" " tag="mdm" backgroundImage={HomeImage} />
                <div className="MDM">
                    <span>MEDICAL DEVICE</span><br/><span className="next-line">MAKE-A-THON</span>
                </div>
                {/*<img src={Logo} alt={"MDM 2025 Logo"} loading="lazy"/>*/}
                <CountdownTimer/>
                <div className="hosts">
                    <span>Hosted by the University of Oklahoma</span>
                    <span>Co-Hosted by the University of Illinois Urbana-Champaign</span>
                </div>
            </header>

            <body>
            <hr/>
            <div className="about">
                <div className="about-general">
                    <h1> About Us </h1>
                    <p>
                        The Medical Device Make-A-Thon is a 48-hour competition where teams of students from diverse academic backgrounds
                        come together to conceptualize, design, and showcase a medical device solution to a real-world medical topic.
                        <br/><br/>
                        Participants will have the opportunity to showcase their creativity and
                        problem-solving skills while engaging in a fun, fast-paced environment.
                        <br/><br/>
                        Students from all over the country are invited to participate
                        in this fun-filled weekend of networking and innovation!
                    </p>
                </div>
                <div className="about-this-year">
                    <h1>2025</h1>
                    <div className="partnership">
                        <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                        X
                        <img src={require("../images/I_logo.png")} alt={"UIUC"} loading={"lazy"}/>
                    </div>
                    {/*<img className="hello-logo" src={require("../images/MDM-Logo.png")} alt={"MDM 2025 Logo"} loading="lazy"/>*/}
                    <h2>Norman, Oklahoma</h2>
                    <h3>Friday, February 28 - Sunday, March 2</h3>
                    <br/><br/>
                    <div className="more-links">
                        <Link to="/info" className="information">More Information</Link>
                        <Link to="https://www.instagram.com/medical_device_makeathon/"
                              target="_blank"
                              rel="noopener noreferrer"
                              id="insta">
                            <img src={require("../images/instagram-small.png")} alt={"insta"} loading={"lazy"}/>
                        </Link>
                        <Link to="/contact">
                            <img src={require("../images/mail.png")} alt={"mail"} loading={"lazy"}/>
                        </Link>
                    </div>

                    {/*<p>learn more button</p>*/}
                </div>
            </div>
            <hr/>

            <div className="more-info">
                <div className="info-box" id="workshop">
                    <h4>Workshop</h4>
                    <ul>
                        <li><span>Participate in SolidWorks, Fusion 360, and 3D Printing workshops to bring your designs to life</span></li>
                        <li><span>Receive mentorship from industry professionals to refine your design</span></li>
                        <li><span>Attend additional presentations designed to support your development</span></li>

                    </ul>
                </div>
                <div className="info-box">
                    <h4>Network</h4>
                    <ul>
                        <li><span>Engage with participants, industry professionals, and university faculty</span></li>
                        <li><span>Build connections with mentors and judges who are experts in their fields</span></li>
                        <li><span>Strengthen peer connections through collaboration and teamwork</span></li>
                    </ul>
                </div>
                <div className="info-box">
                    <h4>Compete</h4>
                    <ul>
                        <li><span>The top 15 teams will advance<br/>to the second round of judging</span></li>
                        <li><span>Showcase 3D-printed final prototypes to a panel of judges</span></li>
                        <li><span>Prize money will be awarded<br/>to the top 5 teams</span></li>
                    </ul>
                </div>
            </div>
            <hr/>
            <div className="schools">
                {/*<h2> PARTICIPATING SCHOOLS </h2>*/}
                <h2>Participating Schools</h2>
                <img className='imagetest' src={require("../images/schools/University_of_Oklahoma_seal.png")} alt={"Oklahoma"} loading="lazy"/>
                <img src={require("../images/schools/University_of_Illinois_seal.png")} alt={"Illinois"} loading="lazy"/>
                <img src={require("../images/schools/University_of_Iowa_seal.png")} alt={"Iowa"} loading="lazy"/>
                <img src={require("../images/schools/Wichita_State_University_seal.svg.png")} alt={"Wichita"} loading="lazy"/>
                <img src={require("../images/schools/University_of_Texas_at_San_Antonio_seal.svg.png")} alt={"UTSA"} loading="lazy"/>
                <img src={require("../images/schools/University_of Nebraska_seal.png")} alt={"Nebraska"} loading="lazy"/>
                <img src={require("../images/schools/University_of_Houston_seal.svg.png")} alt={"Houston"} loading="lazy"/>
                <img src={require("../images/schools/University_of_Missouri_seal.svg.png")} alt={"Missouri"} loading="lazy"/>
                <img src={require("../images/schools/Southeast_Missouri_State_University_Seal.png")} alt={"SouthEast Missouri"} loading="lazy"/>
            </div>
            <hr/>

            {/*<CountdownTimer />*/}
            </body>
        </>
    );
};
export default Home;
