import React from 'react';
import "./schedule.css"

// // Component at completion
// const Completion = () => <span>Insert Register Button</span>;
//
// // Renderer callback with condition
// const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//         // Completed state
//         return <>
//             {/*<h1 className="early-open">Early registration is now open!</h1>*/}
//             {/*<CustomLink to="/register">Registration is now open</CustomLink>*/}
//             {/*<Link to="/register" className="early-open">Early Registration is now open!</Link>*/}
//             <a href="https://docs.google.com/forms/d/e/1FAIpQLSfs4E8IjUiBhEhn-l_EPpb2YvoxWRhdsXsaQBOCFTexrtUqiw/viewform" className="early-open" target="_blank" rel="noopener noreferrer">
//                 Early Registration is now open!
//             </a>
//
//         </>;
//
//     } else {
//         // Countdown
//         return <>
//             <span>{days}</span> <span> : </span>
//             <span>{hours}</span> <span> : </span>
//             <span>{minutes}</span> <span> : </span>
//             <span>{seconds}</span>
//         </>;
//     }
// };

// CountdownTimer Component
const Schedule = () => {
    return (
        <>
            <div className="schedule-container">
                <div className="day">
                    <h3> FRIDAY 02/28 </h3>
                    <div className="events">
                        <span>2pm</span>
                        <div className="time-block" id="three-hours">
                            <p>Check In</p>
                            <p id="event-location">Devon Energy Hall Atrium</p>
                        </div>
                        <span>5pm</span>
                        <div className="time-block" id="one-hour">
                            <p>Fusion 360 / SolidWorks Workshops</p>
                            <p id="event-location">Gallogly Hall 127</p>
                            <p id="event-location">Rawls Engineering Practice Facility 200</p>
                        </div>
                        <span>6pm</span>
                        <div className="time-block" id="one-hour">
                            <p>Dinner</p>
                            <p id="event-location">Devon Energy Hall Atrium</p>
                        </div>
                        <span>7pm</span>
                        <div className="time-block" id="one-hour">
                            <p>Competition Problem Statement</p>
                            <p id="event-location">Reynolds Performing Arts Center</p>
                        </div>
                        <span>8pm</span>
                        <div className="time-block" id="three-hours">
                            <p>Design Period Begins</p>
                            <p id="event-location">Assigned Workrooms</p>
                        </div>
                    </div>
                </div>
                <div className="day">
                    <h3> SATURDAY 03/01 </h3>
                    <div className="events">
                        <span>7am</span>
                        <div className="time-block" id="one-hour">
                            <p>Breakfast</p>
                            <p id="event-location">Devon Energy Hall Atrium</p>
                        </div>
                        <span>8am</span>
                        <div className="time-block" id="one-hour">
                            <p>3D Printing Workshop</p>
                            <p id="event-location">Gallogly Hall 125</p>
                        </div>
                        <span>9am</span>
                        <div className="time-block" id="one-hour">
                            <p>Mentor Session: Block A</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>
                        <span>10am</span>
                        <div className="time-block" id="one-hour">
                            <p>Mentor Session: Block B</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>
                        <span>11am</span>
                        <div className="time-block" id="two-hours">
                            <p>Lunch</p>
                            <p id="event-location">Devon Energy Hall Atrium</p>
                        </div>
                        <span>1pm</span>
                        <div className="time-block" id="two-hours">
                            <p>Work Period</p>
                            <p id="event-location">Assigned Workrooms</p>
                        </div>
                        <div className="time-block" id="deadline">
                            <p>Phase 1 Due</p>
                            <p id="event-location">Email Submission no later than 3pm</p>
                        </div>
                        <span>3pm</span>
                        <div className="time-block" id="onehalf-hour">
                            <p>Prepare for Presentation</p>
                            <p id="event-location">Assigned Workrooms</p>
                        </div>
                        <span>4:00pm</span>
                        <br/>
                        <span>4:15pm</span>
                        <div className="time-block" id="twohalf-hours">
                            <p>Presentations Round 1</p>
                            <p id="event-location">Felgar Hall 300</p>
                            <p id="event-location">Rawls Engineering Practice Facility 200</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>
                        <span>6:45pm</span>
                        <br/>
                        <span>7pm</span>
                        <div className="time-block" id="one-hour">
                            <p>Career Fair</p>
                            <p id="event-location">Molly Shi Boren Ballroom</p>
                        </div>
                        <span>8:30pm</span>
                        <div className="time-block" id="one-hour">
                            <p>Networking Dinner</p>
                            <p id="event-location">Molly Shi Boren Ballroom</p>
                        </div>
                        <span>9:45pm</span>
                        <br/>
                        <span>10pm</span>
                        <div className="time-block" id="half-hour">
                            <p>Top 15 Mentoring Session</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>
                        <span>10:30pm</span>
                        <div className="time-block" id="one-hour">
                            <p>Round 2 Begins</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>

                    </div>
                </div>
                <div className="day">
                    <h3> SUNDAY 03/02 </h3>
                    <div className="events">
                        <span>8am</span>
                        <div className="time-block" id="onehalf-hour">
                            <p>Breakfast</p>
                            <p id="event-location">Devon Energy Hall Atrium</p>
                        </div>
                        <span>9:30am</span>
                        <div className="time-block" id="two-hours">
                            <p>Work Period</p>
                            <p id="event-location">Assigned Workrooms</p>
                        </div>
                        <div className="time-block" id="deadline">
                            <p>Phase 2 Due</p>
                            <p id="event-location">Email Submission no later than 11:30</p>
                        </div>
                        <span>11:30pm</span>
                        <br/>
                        <span>12pm</span>
                        <div className="time-block" id="onehalf-hour">
                            <p>Presentations Round 2, Pt 1</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>
                        <span>1:30</span>
                        <div className="time-block" id="onehalf-hour">
                            <p>Lunch</p>
                            <p id="event-location">Gallogly Lobby</p>
                        </div>
                        <span>2pm</span>
                        <div className="time-block" id="two-hours">
                            <p>Presentations Round 2, Pt 2</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>
                        <span>4pm</span>
                        <br/>
                        <span>4:15pm</span>
                        <div className="time-block" id="half-hour">
                            <p>Award Ceremony</p>
                            <p id="event-location">Gallogly Hall 127</p>
                        </div>



                    </div>
                </div>
                {/*<h4>Early Registration</h4>*/}
                {/*<h6>Open for a limited time</h6>*/}
                {/*<div><Countdown*/}
                {/*    date={'2024-11-11T08:00:00'}*/}
                {/*    renderer={renderer}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*<div className="time-label">*/}
                {/*    <span>DAYS</span>*/}
                {/*    <span>HOURS</span>*/}
                {/*    <span>MINUTES</span>*/}
                {/*    <span>SECONDS</span>*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default Schedule;
