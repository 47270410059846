import React from "react";
import Header from "../components/header/header";
import FAQ from "../components/extras/FAQ";
import Schedule from "../components/extras/schedule";
import '../App.css';
import '../style/info.css';
import HeaderImage from "../images/headers/2025circuit.png";

const Info = () => {
    return (
        <>
            <Header text="" text2="" tag="info" backgroundImage={HeaderImage}/>
            <div className="this-year">
                <h1> 2 0 2 5 </h1>
                <Schedule/>
                <div className="contact-method" id="groupMe">
                    {/* GroupMe QR Codes */}
                    <h3>MDM Announcements</h3>
                    <img src={require("../images/QR-Announcements.png")} alt={"GroupMe Announcements"} loading="lazy"/>
                    <h3>MDM Student Chat</h3>
                    <img src={require("../images/QR-Students.png")} alt={"GroupMe Student Chat"} loading="lazy"/>
                </div>
                <br/><br/>
                <div className="location-details">
                    <img src={require("../images/OUareaMap.png")} alt={"Campus Temporary Map"} loading="lazy"/>
                </div>
                <br/><br/><br/>
            </div>

            <FAQ/>

        </>
    );
}

export default Info;