import React from 'react';
import Countdown from 'react-countdown';
import { Link, useMatch, useResolvedPath} from "react-router-dom";
// import CustomLink from "../navigation/customlink";
import "./countdown.css"

// Component at completion
const Completion = () => <span>Insert Register Button</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Completed state
        return <>
            {/*<h1 className="early-open">Early registration is now open!</h1>*/}
            {/*<CustomLink to="/register">Registration is now open</CustomLink>*/}
            {/*<Link to="/register" className="early-open">Early Registration is now open!</Link>*/}
            {/*<a href="https://docs.google.com/forms/d/e/1FAIpQLSfs4E8IjUiBhEhn-l_EPpb2YvoxWRhdsXsaQBOCFTexrtUqiw/viewform" className="early-open" target="_blank" rel="noopener noreferrer">*/}
            {/*    Early Registration is now open!*/}
            {/*</a>*/}
            <h2 className="countdown"> Registration Closed </h2>
        </>;

    } else {
        // Countdown
        return <>
            <span>{days}</span> <span> : </span>
            <span>{hours}</span> <span> : </span>
            <span>{minutes}</span> <span> : </span>
            <span>{seconds}</span>

            {/*<span>{days}</span>*/}
            {/*<span>{hours}</span>*/}
            {/*<span>{minutes}</span>*/}
            {/*<span>{seconds}</span>*/}
        </>;
    }
};

// CountdownTimer Component
const CountdownTimer = () => {
    return (
        <>
            <div className="countdown">
                <h4>Registration Open</h4>
                {/*<h6>for a limited time</h6>*/}
                <div><Countdown
                    date={'2025-02-15T00:00:00'}
                    renderer={renderer}
                />
                </div>
                <div className="time-label">
                    <span>DAYS</span>
                    <span>HOURS</span>
                    <span>MINUTES</span>
                    <span>SECONDS</span>
                </div>
            </div>
        </>
    );
};

export default CountdownTimer;
