import Header from "../components/header/header";
import HeaderImage from "../images/OurTeam.png";
import React from "react";
import '../style/team.css'
import '../App.css';

const Team = () => {
    return (
        <>
            <header>
                <Header text="Our Team" tag="team" backgroundImage={HeaderImage}/>
            </header>
            <body>
            {/*<span className="cover"/>*/}
            <div className="first">
                <div className="director">
                    <img src={"/team/Pauli-Luke.jpg"} alt={"Luke Pauli"} loading={"lazy"}/>
                    <div>
                        <p className="dir-name">Luke Pauli</p>
                        <div className="vertical-line">
                            <h3>Director </h3>
                            {/*<span className="university">University of Oklahoma</span>*/}
                            {/*<span className="major">Biomedical Engineering</span>*/}
                            {/*<span className="class_year">Senior</span>*/}
                            <p>
                                Hi everyone, my name is Luke Pauli and I am serving as the Director of the 2025 National
                                Medical Device Make-A-Thon. After competing in the previous 2 Make-A-Thons at Texas A&M and
                                UTSA, I am honored to continue the legacy of this competition at the University of Oklahoma.
                                <br/>
                                I am extremely excited for this year's competition and along with committee members from
                                both OU and UIUC, we have been dedicated to ensuring this is the best Make-A-Thon yet.
                            </p>
                            <p>
                                Outside of the MDM, I serve as the president of BMES and a TA for Biomechanics,
                                am involved with JHLP and OU Chi Alpha, and do undergraduate research in the Chen Lab.
                                For fun, I love playing and watching sports, hanging out with my friends, and travelling.
                            </p>
                            <p>
                                After college, I hope to either go back to Pfizer as a full-time engineer or
                                pursue graduate school. I look forward to meeting everyone at this year's Make-A-Thon!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="side-year">
                    <p>2</p>
                    <p>0</p>
                    <p>2</p>
                    <p>5</p>
                </div>
            </div>
            <div className="second">
                <div className="coordinator" id="ou">
                    <img src={"/team/Herrera.jpg"} alt={"Nathan Herrera"} loading={"lazy"}/>
                    <div>
                        <h1>Nathan Stryker Herrera</h1>
                        {/*<h2>Mechanical Engineering | Senior</h2>*/}
                        <h3>Head Coordinator - University of Oklahoma</h3>
                        <p>
                            Hey everyone, my name is Stryker and I am incredibly excited to serve as the Head Coordinator
                            of the 2025 National Medical Device Make-A-Thon! I have a deep passion for supporting my friends,
                            student organizations, and the university community. My journey with the National Medical Device
                            Make-A-Thon began after the 2024 competition when Luke and I discussed the potential
                            of hosting the event ourselves. I saw immense value in the idea and jumped head-first into
                            co-writing our proposal and gearing up for this tremendous chance. The opportunity to be
                            part of something so impactful and innovative drives me every day.
                        </p>
                        <p>
                            Outside of MDM, I am actively involved in several student organizations where I strive to
                            make a difference. I currently serve as the President of OU Chi Alpha, where I've led a
                            small group for the past two years. I'm also the Campus Connections Co-Chair for the
                            Jerry Holmes Leadership Program for Engineers and Scientists. Becoming a Holmes Leadership
                            Associate last year has provided me with invaluable leadership development through
                            professional mentorship and hope to serve the org with dignity, leaving it better than when I came in.
                            As the Outreach Chair for the American Society of Mechanical Engineers (ASME), I’m dedicated
                            to enhancing our chapter's support for mechanical engineering students. Lastly, as an Engineering
                            Pathways Mentor, I recognize the importance of mentorship and am committed to helping
                            freshmen navigate their transition to college life.
                        </p>
                    </div>
                </div>
                <div className="coordinator" id="uiuc">
                    <img src={require("../images/MDM-Logo.png")} alt={"Brendan O'Rourke"} loading={"lazy"}/>
                    <div>
                        <h1>Brendan O'Rourke</h1>
                        {/*<h2>Biomedical Engineering | Senior</h2>*/}
                        <h3>Head Coordinator - University of Illinois UC</h3>
                        {/*<p></p>*/}
                    {/*</div>*/}
                    {/*<div className="member-info">*/}
                    {/*    <h3 className="position">Head Coordinator</h3>*/}
                        {/*<h3 className="name"></h3>*/}
                        {/*<h5 className="university">University of </h5>*/}
                        {/*<h5 className="major">Biomedical Engineering</h5>*/}
                        {/*<h5 className="class_year">Senior</h5>*/}
                        <p>Brendan O'Rourke is crafting a personal message to be added soon!</p>
                    </div>
                </div>
            </div>
            <div className="team">
                <div className="team-member">
                    <img src={"/team/Perez.png"} alt={"Haley Perez"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Head of Website Design</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Haley Perez</h3>
                                <span className="academics">Computer Science | Senior</span>
                            </div>
                        </div>
                        <p> Haley is an undergraduate research assistant at the University of Oklahoma
                            pursuing a bachelor’s degree in Computer Science with a minor in Mathematics and
                            additional studies in Engineering Leadership. Her research focuses on data augmentation bias
                            and ethics of Artificial Intelligence applied to Atmospheric and Oceanic Sciences.
                            As head of website design for the Medical Device Make-a-thon, she rebuilt the website from
                            scratch, transitioning the platform to AWS.
                            Outside of academics, Haley has a background in hospitality and musical theater.
                            She enjoys spending time outdoors with her kids and advocates for native gardening
                            and conservation.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Estell.jpg"} alt={"Alonna Estell"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Amanuensis & Meeting Scheduler</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Alonna Estell</h3>
                                <span className="academics">Biomedical Engineering | Sophomore</span>
                            </div>
                        </div>
                        <p>
                            My name is Alonna Estell and I am the Amanuensis and meeting scheduler for the 2025 Medical
                            Device Make-a-Thon. I am a sophomore studying Biomedical Engineering at the University of
                            Oklahoma. I am a member of Broadening Engineering Impacts and work in Dr. Acar’s lab doing cell
                            research using peptides. I also currently work as a Physical Therapy Tech for a private
                            contractor. I spend my free time reading, drawing, designing clothes, and traveling.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Tarwirei.png"} alt={"Natasha Tarwirei"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Treasurer</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Natasha Tarwirei</h3>
                                <span className="academics">Biomedical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            Hey everyone! My name is Natasha and I am an international student from Zimbabwe. I am currently
                            a rising senior studying Biomedical Engineering at the University of Oklahoma. I have a keen
                            interest in the design and manufacturing of medical devices and hope to pursue this as a career.
                            Outside the classroom, I enjoy reading a good book, traveling and I love being in nature.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Gonzales.png"} alt={"Mia Gonzales"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Head of Social Media<br/>Logo and T-Shirt Designer</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Mia Gonzales</h3>
                                <span className="academics">Biomedical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            Hi! My name is Mia Gonzales and I am this year's Head of Social Media and Logo/T-shirt Design!
                            I'm a senior in Biomedical Engineering and I plan on getting my master's degree here at OU. I am
                            from El Paso, TX and I have 3 dogs back home. I love running, reading, and playing/listening to
                            music. What I am most excited for during the make-a-thon is meeting the teams and seeing all the
                            ideas everyone comes up with!
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Lowe.png"} alt={"James Lowe"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Event Coordinator & Fusion360</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">James Lowe</h3>
                                <span className="academics">Biomedical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            I am an undergraduate at OU pursuing an Accelerated Master's degree for research in
                            imaging and nanotechnology, after which I plan to go to medical school. In the meantime, I am
                            the co-founder of an OU club STEM in the Sun, the treasurer of OU BMES, and an Event Coordinator
                            in this year's MDM. I enjoy hiking, building things, and guitar.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Gihozo-Rukundo.png"} alt={"Modeste Gihozo Rukundo"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Events Coordinator</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name" id="mp">Modeste<br/>Gihozo Rukundo</h3>
                                <span className="academics">Biomedical Engineering | Junior</span>
                            </div>
                        </div>
                        <p>
                            Modeste is a United World Colleges Davis Scholar from Rwanda, a Broadening Engineering Impact
                            Scholar, Engineering Just Futures Fellow, and 2024 Stanford Engineering Summer Undergraduates
                            Research Fellow. Modeste is a member of OU chapters of the Society of Biomedical Engineers and
                            the National Society of Black Engineers, a Jerry Holmes Leadership Associate, and has served as
                            a Way-finding mentor for Women in Engineering. Outside the college of Engineering, Modeste
                            serves as an ambassador and historian of the University of Oklahoma through Crimson Club, she is
                            a member of President’s Leadership Class’62, and has served as the events coordinator of the
                            African Women’s League as well as co-chair of the programming and logistics committee of the
                            Honors Students Diversity Council. Earlier this year, Modeste was recognized by the University
                            of Oklahoma as the Outstanding Sophomore. In her free time, Modeste enjoys playing Basketball
                            and writing poems.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={require("../images/MDM-Logo.png")} alt={"Nosipho Mpanza"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Event Coordinator</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Nosipho Mpanza</h3>
                                <span className="academics">Biomedical Engineering | Junior</span>
                            </div>
                        </div>
                        <p>
                            Nosipho Mpanza is a 3rd Year Biomedical Engineering student
                            and an Event Coordinator at the University of Oklahoma
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Baharestani.png"} alt={"Brian Baharestani"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Head of Sponsorship</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Brian Baharestani</h3>
                                <span className="academics">Biomedical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            My name is Brian Baharestani and I am the Head of Sponsorship for the Make-a-Thon this year.
                            I am a Biomedical Engineering student at OU doing the accelerated BS/MS degree plan.
                            I do cell-based research on immunoadjuvants and laser therapies for cancer. I also have
                            experience in V&V Engineering and working towards FDA approval for a medical device.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Baid.png"} alt={"Akshay Baid"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Sponsorship Coordinator</h3>
                        <div className="person" id="illinois">
                            <img src={require("../images/I_logo.png")} alt={"UIUC"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Akshay Baid</h3>
                                <span className="academics">Bioengineering | Freshman</span>
                            </div>
                        </div>
                        <p>
                            Hi! My name is Akshay Baid, and I’m a freshman studying bioengineering at UIUC.
                            I’m passionate about creating accessible healthcare solutions to improve quality of life.
                            In my free time, I enjoy playing tennis, going to the gym, and listening to music.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Sydney.png"} alt={"Syndey Kirages"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Sponsorship Coordinator</h3>
                        <div className="person" id="illinois">
                            <img src={require("../images/I_logo.png")} alt={"UIUC"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Syndey Kirages</h3>
                                <span className="academics">Bioengineering | Freshman</span>
                            </div>
                        </div>
                        <p>
                            Sydney has experience in volunteering, leadership, lobbying, and patient interaction,
                            particularly with children.
                            On campus, she a member of The Cancer Scholars Program, Biomedical Engineering Society,
                            Women in Engineering, Society of Women Engineers, the Innovation Living Learning Community,
                            Grainger Engineering First Year Experience, Medical Book Club, Illinois Health Mentoring Group,
                            and is a volunteer at Carle Hospital. She also runs a non profit organization, IvyWhale.com
                            and is the author of "Stories With Pop Pop Women in Disguise - The Civil War.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Batra.jpeg"} alt={"Mehak Batra"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Recruitment Chair</h3>
                        <div className="person" id="illinois">
                            <img src={require("../images/I_logo.png")} alt={"UIUC"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Mehak Batra</h3>
                                <span className="academics">Bioengineering | Freshman</span>
                            </div>
                        </div>
                        <p>
                            Mehak is a freshman in bioengineering and is passionate about improving healthcare and
                            innovating treatments to help people live longer, healthier lives. On campus, she is a
                            member of the Cancer Scholars Program, I-Made, Medlaunch, Biomedical Engineering Society,
                            Society of Women Engineers, Grainger First-year experience in innovation, and the Leadership
                            Living Learning Community. In her free time, she likes to dance, watch TV shows & movies,
                            and playing sports.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Matson.jpg"} alt={"Cayman Matson"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Logistics Coordinator</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Cayman Matson</h3>
                                <span className="academics">Biomedical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            Cayman Matson is an undergraduate studying Biomedical Engineering, Data Analytics, and
                            Chinese at the University of Oklahoma. He currently works in the Hearing and Balance lab at
                            OU and has focused primarily on the development of virtual models of the inner ear.</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Fisher.png"} alt={"Mecca Fisher"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Judge & Mentor Coordinator</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Mecca Fisher</h3>
                                <span className="academics">Biomedical Engineering | Junior</span>
                            </div>
                        </div>
                        <p>
                            Mecca Fisher is a 3rd Year Biomedical Engineering student
                            and a Judge & Mentors Coordinator at the University of Oklahoma
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Cunningham.png"} alt={"Joshua Cunningham"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Judge & Mentor Coordinator</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Joshua Cunningham</h3>
                                <span className="academics">Biomedical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            Joshua is currently employed as a biomedical engineer/project manager at NobleMed.
                            In his free time, he enjoys working the trades, and spending time with his family.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Sajawal.jpg"} alt={"Mohammad Sajawal"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Outreach & Volunteer Coordinator</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Mohammad Sajawal</h3>
                                <span className="academics">Biomedical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            Mohammad is a dedicated biomedical engineer passionate about healthcare innovation and medical
                            outreach. He is pursuing a pre-medical track and integrates his engineering expertise with his
                            desire to contribute to the medical field. Graduating from the University of Oklahoma, Mohammad
                            actively mentors incoming freshmen engineers, guiding them through the challenges of academia
                            and offering invaluable insights into the profession.
                            His commitment to service extends beyond the classroom as Mohammad serves as the Outreach and
                            Volunteer Coordinator for the 2025 Medical Device Make-a-Thon at the University of Oklahoma. In
                            this role, he orchestrates events that bring together students, faculty, and industry
                            professionals from all over the United States to collaborate on designing innovative medical
                            devices aimed at addressing pressing healthcare needs.
                            Furthermore, Mohammad is deeply involved in community service, volunteering his time at a local
                            clinic where he provides essential care to underserved populations. He also works alongside OU
                            EMS as a stretcher team leader during football games, working seamlessly under high-pressure
                            environments. His dedication to improving healthcare access and advancing medical technology
                            exemplifies his unwavering commitment to making a meaningful difference in the lives of others.
                            Mohammad's multifaceted approach to engineering, medicine, and service underscores his
                            remarkable dedication to fostering positive change in both academic and community settings.
                            With his blend of technical expertise and compassionate spirit, he is poised to continue
                            making significant contributions to the intersection of engineering and healthcare.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Taffe.png"} alt={"Haley Taffe"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Facilities Coordinator</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Haley Taffe</h3>
                                <span className="academics">Biomedical Engineering | Graduate</span>
                            </div>
                        </div>
                        <p>
                            I am a Masters Biomedical Engineering major focusing on learning interventions within
                            Engineering Education. My undergraduate degree was also in Biomedical Engineering from the
                            University of Oklahoma where I focused on Nanomedicine for drug delivery and diagnostic
                            purposes. I enjoy reading and playing with my puppy in my free time.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <img src={"/team/Rhule.jpg"} alt={"Davin Rhule"} loading={"lazy"}/>
                    <div className="member-info">
                        <h3 className="position">Solid Works & 3D Printing Instructor</h3>
                        <div className="person">
                            <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                            <div>
                                <h3 className="name">Davin Rhule</h3>
                                <span className="academics">Mechanical Engineering | Senior</span>
                            </div>
                        </div>
                        <p>
                            Davin Rhule serves as the SolidWorks and 3D Printer Instructor for the 2025 Medical Device
                            Make-A-Thon at the University of Oklahoma. As a graduate student in mechanical engineering,
                            his research focuses on advanced 3D printing techniques for lightweight structural composites.
                            Davin leads hands-on workshops, teaching students how to model in SolidWorks and utilize
                            3D printing for rapid prototyping. His expertise in materials, CAD, and manufacturing is
                            instrumental in helping participants bring innovative medical device concepts to life.
                            He is also committed to fostering collaboration and skill development within the competition.
                        </p>
                    </div>
                </div>
            </div>
            </body>
        </>
    );
}
export default Team;
