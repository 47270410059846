import Header from '../components/header/header'
import HeaderImage from '../images/headers/ou-flowers.jpg'
import Diamond from '../images/Diamond.png'
import '../style/sponsor.css'
import '../App.css';
import React from "react";
import Button from "react";

const Sponsor = () => {
    return (
        <>
            <header>
                <Header text="To Our Sponsors," text2="Thank You!" tag="sponsorship" backgroundImage={HeaderImage}/>
                <div className="thankyou">
                    <h5>
                        This event wouldn't be possible without you!
                    </h5>
                    <p>
                        We extend our deepest gratitude to all our current <br/>
                        and future sponsors for their invaluable contributions. <br/>
                        Your generosity and commitment will help shape the <br/>
                        skills and potential of tomorrow's Biomedical Engineers.
                    </p>
                </div>
            </header>

            <body>
            <div className="sponsor-page">
                {/*    DIAMOND LEVEL SPONSOR    */}
                <div className="feature-diamond">
                    <div id="year">2<br/>0</div>
                    <div id="feature-glow">
                        {/* DIAMOND LEVEL PLACEHOLDER */}
                        <img src={Diamond} alt={"Diamond Sponsor"} className="feature" loading="lazy"/>
                    </div>
                    {/* UPDATE YEAR */}
                    <div id="year">2<br/>5</div>
                </div>
                {/*    ALL CURRENT SPONSORS    */}
                <div className="sponsors">
                    <h2> OUR SPONSORS </h2>
                    <img src={require("../images/sponsors/LifeScienceOK.png")} alt={"Life Science Oklahoma"} loading="lazy"/>
                    <img src={require("../images/sponsors/OKCchamber.jpeg")} alt={"OKC Chamber"} loading="lazy"/>
                    <img src={require("../images/sponsors/ou-bme.png")} alt={"sponsor"} loading="lazy"/>
                    <img src={require("../images/sponsors/BMES2019.png")} alt={"BME UIUC"} loading="lazy"/>
                    {/*<img src={require("../images/sponsors/Project-Medtech.png")} alt={"Project MedTech"} loading="lazy"/>*/}
                    <img src={require("../images/sponsors/Society_of_Petroleum_Engineers_Logo.svg.png")}
                         alt={"Society of Petroleum Ingineers"} loading="lazy"/>
                    <img src={require("../images/sponsors/Coca-Cola.png")} alt={"Coca-Cola"} loading="lazy"/>
                    <img src={require("../images/sponsors/Medtronic.jpeg")} alt={"Medtronic"} loading="lazy"/>
                    <img src={require("../images/sponsors/immy.jpeg")} alt={"Immy"} loading="lazy"/>



                    {/* Use this as a place holder */}
                    {/*<img src={require("../images/logoplaceholder.png")} alt={"logo place holder"} id="placeholder"/>*/}
                </div>

                <div className="become-sponsor">
                    BECOME A SPONSOR
                </div>
                <div className="sponsor-tiers">
                    {/* GOLD LEVEL SPONSOR */}
                    <div className="border" id="gold">
                        <div className="tier">
                            <h4>GOLD</h4>
                            <p className="price">$5000</p>
                            <ul>
                                <li><span>Table at Career Fair</span></li>
                                <li><span>Company Logo on<br/>MDM Website & T-Shirts</span></li>
                                <li><span>Company Information<br/>Sent Out to Each Participant</span></li>
                                <li><span>Access to the 200 participant<br/>Resume Bank</span></li>
                            </ul>
                        </div>
                    </div>
                    {/* SILVER LEVEL SPONSOR */}
                    <div className="border" id="silver">
                        <div className="tier">
                            <h4>SILVER</h4>
                            <p className="price">$2500</p>
                            <ul>
                                <li><span>Table at Career Fair</span></li>
                                <li><span>Company Logo on<br/>MDM Website & T-Shirts</span></li>
                                <li><span>Company Information<br/>Sent Out to Each Participant</span></li>
                                <li><span id="darkaccess">Access to the 200 participant<br/>Resume Bank</span></li>
                            </ul>
                        </div>
                    </div>
                    {/* BRONZE LEVEL SPONSOR*/}
                    <div className="border" id="bronze">
                        <div className="tier">
                            <h4>BRONZE</h4>
                            <p className="price">$1000</p>
                            <ul>
                                <li><span>Table at career fair</span></li>
                                <li><span id="darkaccess">Company Logo on<br/>MDM Website & T-Shirts</span></li>
                                <li><span id="darkaccess">Company Information<br/>Sent Out to Each Participant</span></li>
                                <li><span id="darkaccess">Access to the 200 participant<br/>Resume Bank</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*<Button title="Sponsor-Button" onPress={()=>}></Button>*/}
                <div className="sponsor-tiers">
                    {/* DIAMOND LEVEL SPONSOR */}
                    <div className="diamond glow">
                        <div className="tier" id="diamond-tier">
                            <div className="diamond-split">
                                <h4>DIAMOND</h4>
                                <img src={Diamond} alt={"Diamond Sponsor"} loading="lazy"/>
                                <p className="price">$10,000</p>
                            </div>
                            <ul>
                                <li><b>Choose the Clinical Issue</b></li>
                                <li><span>Choose location at Career Fair</span></li>
                                <li><span>Biggest Company Logo on MDM Website & T-Shirts</span></li>
                                <li><span>Company Information Sent Out to Each Participant</span></li>
                                <li><span>Access to the 200 participant Resume Bank</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </body>
        </>
    );
};
export default Sponsor;